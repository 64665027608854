<template>
<!-- 到家基础参数配置页面-->
<!-- 用百货，商超首页配置那个JSON传参的接口 定义这个页面传参类型为baseParameter -->
<div>
    <!-- header -->
    <div class="header">
        <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" active-text-color="#409EFF">
            <el-menu-item index='1'>丽日江北店</el-menu-item>
            <el-menu-item index="2">丽日湖畔店</el-menu-item>
            <el-menu-item index="3">丽日香榭里大街店</el-menu-item>
            <el-menu-item index="4">丽日生活汇</el-menu-item>
            <el-menu-item index="5">丽日河源中心城店</el-menu-item>
        </el-menu>
        <div class="header_button">
            <el-tooltip class="item" effect="dark" content="将当前门店设置为模板通用数据！" placement="top">
                <el-button type="primary" @click="setTemplate()">保存为模板</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="套用模板数据，注意更改门店经营时间差异！" placement="top">
                <el-button type="primary" @click="useTemplate()">套用模板</el-button>
            </el-tooltip>
            <el-button type="primary" @click="saveBaseMessage">保存</el-button>
        </div>
    </div>
    <el-form ref="form" :model="form" label-width="120px">
        <!-- 做个区分基础设置放左边，免运活动条件放右边 -->
        <div class="listPart ListPartBorder">
            <el-form-item label="每单打包费：">
                <el-input v-model.trim="form.packingFee">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
            <el-form-item label="自提周期：">
                <el-input v-model.trim="form.invalidCycle">
                    <template slot="append">天</template>
                </el-input>
            </el-form-item>
            <el-form-item label="配送方式：">
                <el-radio-group v-model="form.deliveryOptions">
                    <el-radio :label="0">仅配送到家</el-radio>
                    <el-radio :label="1">自提+配送</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="基础配送费：">
                <el-input v-model.trim="form.baseFreight" type="number">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
            <el-form-item label="配送范围：" >
                <el-tooltip class="item" effect="dark" content="设为0表示不做特别范围设置" placement="top" >
                <el-input v-model.trim="form.overDistance" oninput="value=value.replace(/\D/^0/g,'')" onblur="value=value .replace(/\D/^0/g,'')">
                    <template slot="append">米</template>
                </el-input>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="门店营业时间：">
                <el-time-select
                    placeholder="起始时间"
                    v-model="form.openStartTime"
                    :picker-options="{
                    start: '06:00',
                    step: '00:05',
                    end: '24:00'
                    }">
                </el-time-select>
                <el-time-select
                    placeholder="结束时间"
                    v-model="form.openEndTime"
                    :picker-options="{
                    start: '06:00',
                    step: '00:05',
                    end: '24:00',
                    minTime: form.openStartTime
                    }">
                </el-time-select>
            </el-form-item>
            <el-form-item label="到家配送时间：">
                <el-time-select
                    placeholder="起始时间"
                    v-model="form.deliverStartTime"
                    :picker-options="{
                    start: form.openStartTime,
                    step: '00:15',
                    end: '24:00'
                    }">
                </el-time-select>
                <el-time-select
                    placeholder="结束时间"
                    v-model="form.deliverEndTime"
                    :picker-options="{
                    start: '06:00',
                    step: '00:15',
                    end: '24:00',
                    minTime: form.deliverStartTime
                    }">
                </el-time-select>
            </el-form-item>
        </div>
        <div class="listPart">
            <el-form-item label="免运范围：" >
                <el-tooltip class="item" effect="dark" content="设为0表示没有免运活动！" placement="top" >
                <el-input v-model.trim="form.freeDistance" oninput="value=value.replace(/\D/^0/g,'')" onblur="value=value .replace(/\D/^0/g,'')">
                    <template slot="append">米</template>
                </el-input>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="免运项目：">
                <el-radio-group v-model="form.itemOptions">
                    <el-radio :label="0">仅免运费</el-radio>
                    <el-radio :label="1">运费+打包费</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="免运金额门槛：">
                <el-tooltip class="item" effect="dark" content="设为0表示没有免运活动！" placement="top" >
                <el-input v-model.trim="form.freeAmount" oninput="value=value.replace(/\D/^0/g,'')" onblur="value=value .replace(/\D/^0/g,'')">
                    <template slot="append">元</template>
                </el-input>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="免费金额规则：">
                <el-tooltip class="item" effect="dark" content="商品金额达到门槛,指即便用户使用优惠后实付额没有达到金额门槛仍可享受免运" placement="top" >
                <el-radio-group v-model="form.amountOptions">
                    <el-radio :label="0">商品金额达到门槛</el-radio>
                    <el-radio :label="1">实付金额达到门槛</el-radio>
                </el-radio-group>
                </el-tooltip>
            </el-form-item>
        </div>
        
        
    </el-form>
</div>
</template>
<script>
import {saveCanvas,queryCanvas} from "@/api/market/canvas";
export default {
  data() {
    return {
      activeIndex:'1',
      form:{
          packingFee:'',//打包费
          invalidCycle:'',//自提周期
          deliveryOptions:1,//配送方式
          baseFreight:'',//基础运费
          freeDistance:'',//免运范围
          freeAmount:'',//免运金额门槛
          openEndTime:"",//门店开业时间
          openStartTime:"",//门店当日结束营业时间
          deliverEndTime:"",//门店到家配送最早起始时间
          deliverStartTime:"",//门店到家配送最晚结束时间
          itemOptions:0,//免运项目，0仅仅只是免运费，1运费打包费都免
          amountOptions:0,//0商品金额达到门槛即可免运，用户可以继续使用优惠，1用户使用优惠后实付额仍要大于门槛金额才能免运
          overDistance:'0',//最大配送距离
      },
      baseForm:{
          packingFee:'',//打包费
          invalidCycle:'',//自提周期
          deliveryOptions:1,//配送方式
          baseFreight:'',//基础运费
          freeDistance:'',//免运范围
          freeAmount:'',//免运金额门槛
          openEndTime:"",//门店开业时间
          openStartTime:"",//门店当日结束营业时间
          deliverEndTime:"",//门店到家配送最早起始时间
          deliverStartTime:"",//门店到家配送最晚结束时间
          itemOptions:0,//免运项目，0仅仅只是免运费，1运费打包费都免
          amountOptions:0,//0商品金额达到门槛即可免运，用户可以继续使用优惠，1用户使用优惠后实付额仍要大于门槛金额才能免运
          overDistance:'0',//最大配送距离 
      },
      backForm:{
          packingFee:'',//打包费
          invalidCycle:'',//自提周期
          deliveryOptions:1,//配送方式
          baseFreight:'',//基础运费
          freeDistance:'',//免运范围
          freeAmount:'',//免运金额门槛
          openEndTime:"",//门店开业时间
          openStartTime:"",//门店当日结束营业时间
          deliverEndTime:"",//门店到家配送最早起始时间
          deliverStartTime:"",//门店到家配送最晚结束时间
          itemOptions:0,//免运项目，0仅仅只是免运费，1运费打包费都免
          amountOptions:0,//0商品金额达到门槛即可免运，用户可以继续使用优惠，1用户使用优惠后实付额仍要大于门槛金额才能免运
          overDistance:'0',//最大配送距离 
      },
      modelType:'baseParameter'
    };
  },
  mounted() {
    this.queryBaseMessage()
  },
  methods: {
    //提交配置信息
    saveBaseMessage(){
        let params = {
            storeId:this.activeIndex,
            tabBarCode:this.modelType,//写死的，百货canvas标识
            canvasJson:JSON.stringify(this.form)   
        };
        saveCanvas(params).then(res=>{
            console.log(res)
            if(res.data.msg && res.data.msg == '成功'){
                this.$message({
                    message: '保存成功！',
                    type: 'success',
                    duration:5000
                });
            }else{
               this.$message({
                    message: '保存失败！',
                    type: 'error',
                    duration:5000
                }); 
            }
        }).catch(err=>{
            this.$message({
                    message: err,
                    type: 'error',
                    duration:5000
                });
        })
    },
    //获取配置信息
    queryBaseMessage(){
        let params={
            tabBarCode:this.modelType,//标识，百货或者商超
            storeId: Number(this.activeIndex),
        }
        queryCanvas(params).then(res=>{
            if(res.data.body){
                this.form = JSON.parse(res.data.body.canvasJson)
            }else{
                this.form = JSON.parse(JSON.stringify(this.baseForm))
            }
            console.log(this.form)
        }).catch(err=>{
            this.$message({
                    message: err,
                    type: 'error',
                    duration:5000
                });
        })
    }, 
    //门店导航交互
    handleSelect(e){
        this.activeIndex = e
        this.queryBaseMessage()
    },
    // 设置模板
    setTemplate(){
        this.backForm = JSON.parse(JSON.stringify(this.form))
        this.$message({
          message: '设置模板成功！',
          type: 'success'
        });
    },
    // 使用模板
    useTemplate(){
        this.form = JSON.parse(JSON.stringify(this.backForm))
    },
  },
};
</script>
<style lang="scss" scoped>
.header{
    position: relative;
    display: flex;
    align-items: center;
}
.header_button{
    position:absolute;
    top: 10px;
    right: 50px;
}
.el-form{
    margin: 36px 0 0 36px;
}
.listPart{
    width: 600px;
    float: left;
}
.ListPartBorder{
    box-sizing: content-box;
    padding-right: 36px;
    border-right: 1px grey dashed;
}
.el-form-item{
    width: 600px;
}
/deep/.el-input-group__append{
    width: 60px;
    text-align: center;
}
</style>